import React from 'react';
import { Layout } from '../components/Layout';
import '../../src/App.css'
import ReactTooltip from "react-tooltip";


const About = () => {
  return (
    <Layout>
      <div className='cards-width about' style={{backgroundColor: "rgba(255, 255, 255, 0.9)"}}>
        <img className="profile-pic" src="../../me.png" />
        <p>Hi, I'm Phillip, a Staff SDET.  I have over 15 years of experience in quality assurance and test automation
          as well as full stack software development.  <br /><br />I'm extremely passionate about software delivery you can have confidence in.  I've been so fortunate to work at places that facilitate my growth.  I strive to provide that same environment for people I work with.<br /><br /> I understand how each part of the stack works and where tests belong in the pyramid.  There's more than one way to go full stack with testing and there are new tools emerging all the time.  That's something that excites me.  It's one of the many reasons I love what I get to do.</p>
        <br />
        <p><strong>Experience with:</strong></p>
        <ReactTooltip />
        <div className='centered'>
          <img className="about-tech" src="../../node-js.png" alt="node-js" data-tip="node-js" />
          <img className="about-tech" src="../../python.png" alt="python" data-tip="python" />
          <img className="about-tech" src="../../c-sharp.png" alt="c-sharp" data-tip="c-sharp" />
          <img className="about-tech" src="../../java.png" alt="java" data-tip="java" />
          <img className="about-tech" src="../../php-logo.svg" alt="php" data-tip="php" />
          <img className="about-tech" src="../../bash.png" alt="bash" data-tip="bash" />
          <img className="about-tech" src="../../selenium.png" alt="selenium" data-tip="selenium" />
          <img className="about-tech" src="../../cypress-logo.png" alt="cypress" data-tip="cypress" />
          <img className="about-tech" src="../../playwright-logo.svg" alt="playwright" data-tip="playwright" />
          <img className="about-tech" src="../../sauce-labs.png" alt="sauce labs" data-tip="sauce labs" />
          <img className="about-tech" src="../../browser-stack.png" alt="browser stack" data-tip="browser stack" />
          <img className="about-tech" src="../../postman-logo.png" alt="postman" data-tip="postman" />
          <img className="about-tech" src="../../graphql.png" alt="graphql" data-tip="graphql" />
          <img className="about-tech" src="../../docker.png" alt="docker" data-tip="docker" />
          <img className="about-tech" src="../../kubernetes.png" alt="k8s" data-tip="k8s" />
          <img className="about-tech" src="../../mysql.svg" alt="mysql" data-tip="mysql" />
          <img className="about-tech" src="../../sql-server.png" alt="sql server" data-tip="sql server"/>
          <img className="about-tech" src="../../oracle-logo.png" alt="oracle db" data-tip="oracle db" />
          <img className="about-tech" src="../../mongo-db-logo.png" alt="mongo db" data-tip="mongo db" />
          <img className="about-tech" src="../../aws.png" alt="aws" data-tip="aws" />
          <img className="about-tech" src="../../google-cloud.png" alt="google cloud" data-tip="google cloud" />
          <img className="about-tech" src="../../azure.png" alt="azure" data-tip="azure" />
          <img className="about-tech" src="../../github.png" alt="github" data-tip="github" />
          <img className="about-tech" src="../../gitlab-icon-rgb.png" alt="gitlab" data-tip="gitlab" />
        </div>
      </div>
    </Layout >
  );
};

export default About;